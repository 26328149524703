export const loadInspectlet = () => {
    const inspectletId = import.meta.env.VITE_INSPECTLET_ID;

    if (!inspectletId) {
        return;
    }

    const mount = () => {
        // @ts-expect-error global
        window.__insp = window.__insp || [];

        // @ts-expect-error global
        __insp.push(['wid', inspectletId]);

        const ldinsp = function () {
            // @ts-expect-error global
            if (typeof window.__inspld != 'undefined') return;

            // @ts-expect-error global
            window.__inspld = 1;
            const insp = document.createElement('script');
            insp.type = 'text/javascript';
            insp.async = true;
            insp.id = 'inspsync';
            insp.src =
                ('https:' == document.location.protocol ? 'https' : 'http') +
                `://cdn.inspectlet.com/inspectlet.js?wid=${inspectletId}&r=` +
                Math.floor(new Date().getTime() / 3600000);
            const x = document.getElementsByTagName('script')[0];

            // @ts-expect-error global
            x.parentNode.insertBefore(insp, x);
        };
        setTimeout(ldinsp, 0);
        console.log('inspectlet loaded');
    };

    mount();
};
